<template>
  <div>
    <h1><font-awesome-icon :icon="['fas', 'phone-alt']"/> 555 - NASE</h1>
    <h1><font-awesome-icon :icon="['fas', 'at']"/> test@test.de</h1>
    <h1><font-awesome-icon :icon="['fas', 'phone-alt']"/> 555 - NASE</h1>
    <h1><font-awesome-icon :icon="['fas', 'at']"/> test@test.de</h1>
    <h1><font-awesome-icon :icon="['fas', 'phone-alt']"/> 555 - NASE</h1>
    <h1><font-awesome-icon :icon="['fas', 'at']"/> test@test.de</h1>
    <h1><font-awesome-icon :icon="['fas', 'phone-alt']"/> 555 - NASE</h1>
    <h1><font-awesome-icon :icon="['fas', 'at']"/> test@test.de</h1>
  </div>
</template>

<script>
export default {};
</script>
