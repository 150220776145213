<template>
<div>
  <h1>Copyright by:</h1><br>
  <h2>Damir</h2><br>
  <h2>Gabriel</h2><br>
  <h2>Jan</h2><br>
  <h2>Nils</h2><br>
  <h2>Rupert</h2><br>

  
</div>
</template>

<script>
export default {};
</script>